/*--

TEMPLATE NAME: Sinewave - One Page Hosting Landing Page HTML Template
TEMPLATE URI: - http://froid.works/themeforest/sinewave/resource/
DESCRIPTION:  Hosting HTML Template
VERSION: 1.0
AUTHOR: Ajay Kumar Choudhary
AUTHOR URL: http://codecanyon.net/user/ajay138/
DESIGN BY: Rakesh Kumar

[TABLE OF CONTENTS]

1.0 BUTTONS
2.0 ALERTS
3.0 FORM ELEMENTS
4.0 MENU
5.0 CUSTOM ICONS
6.0 DEFAULT PAGE STYLING
7.0 GENERAL RESET

--*/
@import 'base/variables';
@import 'base/colors_variable';
@import 'base/mixin';
@import 'base/buttons';
@import 'base/alerts';
@import 'base/form';
@import 'base/menu';
@import 'base/custom-icons';
@import 'default';
@import 'base/reset';