/* Form Elements Start */

.input {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 16px;
  max-width: 400px;
  width: calc(100% - 32px);
  vertical-align: top;
}

.input__field {
  position: relative;
  display: block;
  float: right;
  padding: 18px;
  width: 60%;
  border: none;
  border-radius: 0;
  background: #f0f0f0;
  color: #aaa;
  font-family: $open-sans;
  -webkit-appearance: none; /* for box shadows to show on iOS */
}

.input__field:focus {
  outline: none;
}

.input__label {
  display: inline-block;
  float: right;
  /*padding: 0 16px;*/
  width: 100%;
  color: #6a7989;
  font-weight: bold;
  font-size: 100.25%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input__label-content {
  position: relative;
  display: block;
  padding: 0 0 25px 0;
  width: 100%;
  font-size: 14px;
  font-family: $open-sans;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.graphic {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
}

.icon {
  color: #ddd;
  font-size: 150%;
}

/* Madoka */
.input--madoka {
  margin: 20px 0 25px 0;
}

.input__field--madoka {
  width: 100%;
  background: transparent;
  color: #fff;
}

.input__label--madoka {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: left;
  cursor: text;
}

.input__label-content--madoka {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.graphic--madoka {
  -webkit-transform: scale3d(1, -1, 1);
  transform: scale3d(1, -1, 1);
  -webkit-transition: stroke-dashoffset 0.3s;
  transition: stroke-dashoffset 0.3s;
  pointer-events: none;
  stroke: #fff;
  stroke-width: 4px;
  stroke-dasharray: 962;
  stroke-dashoffset: 558;
}

.input__field--madoka:focus + .input__label--madoka,
.input--filled .input__label--madoka {
  cursor: default;
  pointer-events: none;
}

.input__field--madoka:focus + .input__label--madoka .graphic--madoka,
.input--filled .graphic--madoka {
  stroke-dashoffset: 0;
}

.input__field--madoka:focus + .input__label--madoka .input__label-content--madoka,
.input--filled .input__label-content--madoka {
  -webkit-transform: scale3d(0.81, 0.81, 1) translate3d(0, 64px, 0);
  transform: scale3d(0.81, 0.81, 1) translate3d(0, 64px, 0);
  &.text-area {
    -webkit-transform: scale3d(0.81, 0.81, 1) translate3d(0, 90px, 0);
    transform: scale3d(0.81, 0.81, 1) translate3d(0, 90px, 0);
    stroke-width: 2px;
  }
}

/* Form Elements End */